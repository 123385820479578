//REACTJS
import React, { useEffect, useState } from 'react';
//GATSBYJS
import { navigate, Link } from 'gatsby';
//FORMIK & YUP
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
//AWS-AMPLIFY
import { Auth, Hub } from 'aws-amplify';
//APPSYNC-MUTATIONS
import { handleCreateUser } from '../appsync_functions/mutations';
//CONTEXT-API
import { useStateValue } from '../context/StateContext';
import { settingISCONFIRMEDSIGNUP, settingROLE, settingUSERNAME } from '../context/contextapi';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
// Toast
import { Toaster, toast } from 'react-hot-toast';

// Helmet 
import Helmet from "react-helmet";

export default function VerficationCode() {
    const [{ isSignedin, isConfirmedSignup, username, role }, dispatch] = useStateValue();
    const [loading, setLoading] = useState(false)
    const [resendLoading, setResendLoading] = useState(false)
    const [user, setUser] = useState(null);


    useEffect(() => {
        Hub.listen('auth', ({ payload: { event, data } }) => {
            switch (event) {
                case 'signIn':
                case 'cognitoHostedUI':
                    getUser().then((userData) => {
                        setUser(userData);
                        console.log('Signed In:', userData);
                    });
                    break;
                case 'signOut':
                    setUser(null);
                    break;
                case 'signIn_failure':
                case 'cognitoHostedUI_failure':
                    console.log('Sign in failure', data);
                    break;
                default:
            }
        });

        getUser().then((userData) => {
            setUser(userData);
            console.log('Signed In:', userData);
        });
    }, []);


    function getUser() {
        return Auth.currentAuthenticatedUser()
            .then((userData) => userData)
            .catch(() => console.log('Not signed in'));
    }



    const initialValues = {
        code: ""
    }


    const validationSchema = Yup.object().shape({
        code: Yup
            .string()
            .required('Confirmation Code is required'),
    })


    const onSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true);
        setLoading(true)

        setTimeout(async () => {
            try {
                /* using here due to ContextApi disptach accessable only in react components */
                await Auth.confirmSignUp(
                    username,
                    values.code
                );
                console.log('successfully code');

                await handleCreateUser({
                    username,                       //username
                    cognitoUsername: username,      //cognitoUsername
                    role                            //role
                });

                dispatch(settingISCONFIRMEDSIGNUP(false));
                dispatch(settingUSERNAME(""));
                dispatch(settingROLE(""));
                toast.success('Account created successfully', {
                    duration: 4000,
                    position: window.matchMedia("(min-width: 600px)").matches ? "bottom-right" : "bottom-center",

                    style: {
                        backgroundColor: '#d9d9d9',
                        padding: window.matchMedia("(min-width: 600px)").matches ? "20px 30px" : "15px 20px",
                        fontSize: '14px',
                        fontWeight: 'bold'
                    },
                });

                navigate("/");
            }
            catch (error) {
                console.log('error code', error);
                toast.error(error.message.toString(), {
                    duration: 4000,
                    position: window.matchMedia("(min-width: 600px)").matches ? "bottom-right" : "bottom-center",

                    style: {
                        backgroundColor: '#d9d9d9',
                        padding: window.matchMedia("(min-width: 600px)").matches ? "20px 30px" : "15px 20px",
                        fontSize: '14px',
                        fontWeight: 'bold'
                    },
                });
            }
            finally {
                setLoading(false)
            }
            setSubmitting(false);
        }, 1500);
    }


    const resendConfirmationCode = async () => {
        setResendLoading(true)
        try {
            await Auth.resendSignUp(username);
            toast.success('Code resend successfully', {
                duration: 4000,
                position: window.matchMedia("(min-width: 600px)").matches ? "bottom-right" : "bottom-center",

                style: {
                    backgroundColor: '#d9d9d9',
                    padding: window.matchMedia("(min-width: 600px)").matches ? "20px 30px" : "15px 20px",
                    fontSize: '14px',
                    fontWeight: 'bold'
                },
            });
        }
        catch (error) {
            console.log('error resendingcode: ', error);
            toast.error(error.message.toString(), {
                duration: 4000,
                position: window.matchMedia("(min-width: 600px)").matches ? "bottom-right" : "bottom-center",

                style: {
                    backgroundColor: '#d9d9d9',
                    padding: window.matchMedia("(min-width: 600px)").matches ? "20px 30px" : "15px 20px",
                    fontSize: '14px',
                    fontWeight: 'bold'
                },
            });
        }
        finally {
            setResendLoading(false)
        }
    }


    useEffect(() => {
        if (user || !isConfirmedSignup) {
            navigate("/");
        }
    }, [user]);


    if (user || !isConfirmedSignup) return <></>;


    return (
        <div className='flex justify-center font-poppins'>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            </Helmet>
            <Toaster />
            <div className='w-full md:w-[600px] min-h-screen flex flex-col px-5 pt-10'>
                <div>
                    <div className='mt-7'>
                        <h2 className='text-lg text-zinc-800 font-semibold'>activate the account!</h2>
                        <p className='text-sm text-zinc-600'>Please enter the temporary code sent to your email</p>
                    </div>
                </div>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {({ isSubmitting }) => (
                        <Form className="rounded-md flex-grow flex flex-col gap-5 mt-8 sm:mt-16">
                            <div className="flex flex-col gap-2">
                                <label htmlFor="email" className="text-zinc-600 font-medium text-xs sm:text-sm">
                                    Confirmation Code
                                </label>
                                <div className='rounded-lg border-[0.9px] border-zinc-300 py-3 px-4 flex items-center gap-3'>
                                    <Field
                                        type="text"
                                        name="code"
                                        id="code"
                                        className="w-full text-sm focus:outline-none placeholder:text-xs"
                                        placeholder="Enter the Confirmation code"
                                    />
                                </div>
                                <ErrorMessage name="code" component="div" className="text-red-500 text-xs" />
                            </div>
                            <div className='flex justify-between'>
                                <button
                                    type="submit"
                                    className="bg-primary text-white font-medium p-2 pl-4 flex justify-between items-center rounded-full w-[130px] text-center text-sm"
                                    disabled={isSubmitting}
                                >
                                    {
                                        loading ? 'loading ...' :
                                            'Submit'
                                    }
                                    <div className='w-[30px] h-[30px] flex justify-center items-center rounded-full bg-[#556FFF]'>
                                        <MdOutlineKeyboardArrowRight className='font-bold' />
                                    </div>
                                </button>
                                <button
                                    className="bg-[#F4F5F7] text-zinc-800 font-medium p-2 pl-4 text-center rounded-full w-[120px] text-sm"
                                    onClick={resendConfirmationCode}
                                    disabled={resendLoading}
                                >
                                    {
                                        resendLoading ?
                                            'loading ...' :
                                            'Resend Code'
                                    }
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div >
    )
}


export function Head() {
    return (
        <title> Code | MassageOpen </title>
    )
}